/* 公共接口 */
export const Common = {
  fileUpload: `upload/image`, // 图片上传
  videoUpload: `upload/video`, // 视频上传
  pdfUpload: `upload/pdf`, // 视频上传
  apkUpload: `/upload/apk`, // APK上传
  excelUpload: `/upload/excel`, // excel上传
  getCityALLData: `/channel/cityConstant/list`,
  selectMetadataField: '/selectMetadataField', // 元数据查询
  productReplenishField: '/productReplenishField', // 补充信息查询
};

/* 用户权限 */
export const Auth = {
  sendSms: `/login/sms.e`,
  login: `/login/login.e`,
};

/* 渠道管理 */
// 渠道分组
export const ChannelGroup = {
  pageList: '/channelGroup/pageList',
  list: '/channelGroup/list',
  selectById: '/channelGroup/selectById',
  update: '/channelGroup/update',
  insert: '/channelGroup/insert',
};
// 渠道列表
export const Channel = {
  channelPageList: `/channel/pageList`,
  channelList: `/channel/list`,
  getChannelUpdateLog: `/channel/getChannelUpdateLog`,
  channelDetail: `/channel/detail`,
  channelSave: `/channel/save`,
  channelUpdate: `/channel/update`,
  merchant: '/channel/merchant', // 渠道商列表查询
  merchantExport: '/channel/merchant/export', // 渠道商列表查询导出
  merchantStatistics: '/channel/merchantStatistics.e', // 渠道商列表查询
  merchantStatisticsExport: '/channel/merchantStatistics/export.e', // 渠道商列表查询导出
  updatesSettlementAmount: '/channel/updatesSettlementAmount',
  getRulePageList: '/channel/filter/rule/pageList', //渠道规则配置列表查询
  getRuleDetail: '/channel/filter/rule/detail', //渠道规则配置详情查询
  addRule: '/channel/filter/rule/insert', //渠道规则配置新增
  updateRule: '/channel/filter/rule/update', //渠道规则配置修改
  getRuleCityDetail: '/channel/filter/rule/city/detail', //渠道规则配置城市详情
  updateRuleCityDetail: '/channel/filter/rule/city/update', //修改渠道规则配置城市详情
  getRuleUserScoreDetail: '/channel/filter/rule/score/detail', //渠道规则配置用户质量积分体系详情
  updateRuleUserScoreDetail: '/channel/filter/rule/score/update', //修改渠道规则配置用户质量积分体系详情
  getAllRuleList: '/channel/filter/rule/selectList', //渠道规则下拉
  delete: '/channel/delete', //渠道删除
};
// 渠道产品配置列表查询
export const ChannelProductConfig = {
  pageList: '/channelProductConfig/pageList',
  detail: '/channelProductConfig/detail',
  update: '/channelProductConfig/update',
  init: '/channelProductConfig/init',
  reset: '/channelProductConfig/reset',
  updateConfigType: '/channelProductConfig/updateConfigType',
};
// 域名配置
export const Domain = {
  pageList: '/domain/pageList',
  detail: '/domain/detail',
  save: '/domain/save',
  update: '/domain/update',
};

export const DomainBody = {
  page: '/domainBody/page',
  list: '/domainBody/list',
  selectById: '/domainBody/selectById',
  update: '/domainBody/update',
  insert: '/domainBody/insert',
  delete: '/domainBody/delete',
};

// 注册页管理
export const ChannelRegisterPage = {
  list: '/channelRegisterPage/list', // get
  pageList: '/channelRegisterPage/pageList', // get
  selectById: '/channelRegisterPage/selectById', // get
  update: '/channelRegisterPage/update', // post
  insert: '/channelRegisterPage/insert', // post
  delete: '/channelRegisterPage/delete', // {id} post
};

/* 系统管理 */
export const System = {
  /* 系统用户 */
  sysUserPage: '/sys/user/page', // 用户列表查询 get
  sysUserInsert: '/sys/user/insert', // 用户新增 post
  sysUserUpdate: '/sys/user/update', // 用户修改 post
  sysUserDetail: '/sys/user/detail', // 用户详情查询 get
  sysUserRole: '/sys/userRole', // 修改用户角色 post  用户已有角色列表 get
  /* 角色 */
  sysRoleInsert: '/sys/role/insert', // 用户角色保存 post
  sysRoleUpdate: '/sys/role/update', // 用户角色新增 post
  sysRoleDelete: '/sys/role/delete', // 角色 删除 post {id}
  sysRole: '/sys/role', // 基础权限树获取 get
  sysRoleAndMenu: '/sys/roleandmenu', // 添加角色-菜单关联关系 post
  sysRoleDetail: '/sys/role/detail', // 角色详情获取 get
  /* 菜单 */
  sysMenuInsert: '/sys/menu/insert', // 菜单保存 post
  sysMenuUpdate: '/sys/menu/update', // 菜单修改 post
  sysMenuList: '/sys/menu/list', // 查询菜单树（有roleId时会携带是否已选择菜单的结果） get
  sysSelectUserMenus: '/sys/selectUserMenus', // 获取用户已有菜单树 get
  sysMenu: '/sys/menu', // 菜单删除 post {id}
  sysMenuDetail: '/sys/menu/detail', // 查询菜单详情 get
  sysRoleMenuList: '/sys/roleMenu/list', // 查询角色已有菜单列表 get

  getCommissioner: 'sys/user/getCommissioner',
  userInfo: 'sys/user/info',
  getAllUser: '/sys/user/getAllUser',
};

/* 用户管理 */
export const User = {
  page: `/user/page`,
  detail: `/user/detail`,
  logOff: `/user/logOff`,
  operate: `/user/operate`,
};
export const UserSelectCapitalPage = {
  selectCapitalPage: `/user/selectCapitalPage`,
  exportCapital: `/user/exportCapital`,
  capitalRecordPage: `/user/capital/record/page`,
  capitalRecordExport: `/user/capital/record/export`,
};
export const ChannelThird = {
  thirdOperate: `/channel/thirdOperate.e`,
  thirdOperateExport: `/channel/thirdOperate/export.e`,
  selectOperateDetail: `/channel/selectOperateDetail`,
};

/* 贷超管理 */
// 网贷、助贷产品
export const Product = {
  pageList: '/product/pageList',
  detail: '/product/detail',
  insert: '/product/insert',
  update: '/product/update',
  updateStatus: '/product/updateStatus',
  auditPageList: '/product/auditPageList',
  auditStatus: '/product/auditStatus',
  bankConfigResult: '/product/bankConfigResult',
  updatePrice: '/product/updatePrice',
  updateSort: '/product/updateSort',
  updatesSettlementAmount: '/settlement/updatesSettlementAmount',
  searchList: '/product/search/list',
  updateSortPrice: '/product/updateSortPrice',
  listByCategory: '/product/search/listByCategory',
  remoteProductList: '/product/search/list/query',
};
export const LoanProductBag = {
  pageList: '/loanProductBag/pageList',
  exportList: '/loanProductBag/exportList',
  detail: '/loanProductBag/detail',
  getLoanProduct: '/loanProductBag/getLoanProduct',
  saveBasic: '/loanProductBag/saveBasic',
};

//
export const AgencyProduct = {
  pageList: '/agency/product/pageList',
  detail: '/agency/product/detail',
  insert: '/agency/product/insert',
  update: '/agency/product/update',
  updateStatus: '/agency/product/updateStatus',
  auditPageList: '/agency/product/auditPageList',
  auditStatus: '/agency/product/auditStatus',
  bankConfigResult: '/agency/product/bankConfigResult',
  del: '/agency/product/del',
  restoreDel: '/agency/product/restoreDel',
  delRecord: '/agency/product/delRecord',
  restoreProduct: '/agency/product/restoreProduct',
  updateApplyNum: '/agency/product/updateApplyNum',
  updatePrice: '/agency/product/updatePrice',
};

// 产品结算表
export const ProductStatement = {
  pageList: '/settlement/pageList',
  detail: '/settlement/product/detail',
  detailExport: '/settlement/product/detail/export',
  update: '/settlement/product/update',
  rechargePageList: '/settlement/product/recharge/pageList',
  rechargePageListExport: '/settlement/product/recharge/pageList/export',
  rechargeDetail: '/settlement/product/recharge/detail',
  recharge: '/settlement/product/recharge',
  rechargeUpdate: '/settlement/product/recharge/update',
  balancePromptDetail: '/settlement/product/balancePrompt/detail',
  balancePrompt: '/settlement/product/balancePrompt',
};

/* 通知模板管理 */
export const NoticeTemplate = {
  page: '/noticeTemplate/page',
  update: '/noticeTemplate/update',
  insert: '/noticeTemplate/insert',
  enable: '/noticeTemplate/enable',
  selectById: '/noticeTemplate/selectById',
};

/* 埋点管理 */
export const StatCountTypeCode = {
  list: '/statCountTypeCode/list',
  selectById: '/statCountTypeCode/selectById',
  update: '/statCountTypeCode/update',
  insert: '/statCountTypeCode/insert',
  delete: '/statCountTypeCode/delete',
};
export const StatCountType = {
  list: '/statCountType/list',
  selectById: '/statCountType/selectById',
  update: '/statCountType/update',
  insert: '/statCountType/insert',
  delete: '/statCountType/delete',
};
export const StatCountTypeParam = {
  list: '/statCountTypeParam/list',
  selectById: '/statCountTypeParam/selectById',
  update: '/statCountTypeParam/update',
  insert: '/statCountTypeParam/insert',
  delete: '/statCountTypeParam/delete',
};

/* 统计表 */
export const StatCountTable = {
  list: '/statCountTable/list',
  selectById: '/statCountTable/selectById',
  update: '/statCountTable/update',
  insert: '/statCountTable/insert',
  delete: '/statCountTable/delete',
  createTable: '/statCountTable/createTable',
};
export const StatCountTableKey = {
  list: '/statCountTableKey/list',
  selectById: '/statCountTableKey/selectById',
  update: '/statCountTableKey/update',
  insert: '/statCountTableKey/insert',
  delete: '/statCountTableKey/delete',
};
export const StatCountTableKeyUnique = {
  list: '/statCountTableKeyUnique/list',
  selectById: '/statCountTableKeyUnique/selectById',
  update: '/statCountTableKeyUnique/update',
  insert: '/statCountTableKeyUnique/insert',
  delete: '/statCountTableKeyUnique/delete',
};
export const StatCountTableKeyCondition = {
  list: '/statCountTableKeyCondition/list',
  insert: '/statCountTableKeyCondition/insert',
  delete: '/statCountTableKeyCondition/delete',
};

/* 助贷管理 */
// 机构关联管理
export const AgencyBag = {
  pageList: '/agencyBag/pageList',
  exportList: '/agencyBag/exportList',
  detail: '/agencyBag/detail',
  getAgency: '/agencyBag/getAgency',
  saveBasic: '/agencyBag/saveBasic',
};
// 助贷日志
export const PushFail = {
  pageList: '/pushFail/pageList',
  exportList: '/pushFail/exportList',
};
// 充值订单列表
export const AgencyTrans = {
  pageList: '/agencyTrans/pageList',
  exportList: '/agencyTrans/exportList',
  saveBasic: '/agencyTrans/saveBasic',
};
// 获客列表
export const UserOrder = {
  pageList: '/userOrder/pageList',
  detail: '/userOrder/detail',
  tagGroupList: '/tagGroupList',
  changeStar: '/userOrder/changeStar',
  changeCity: '/userOrder/changeCity',
  changeTags: '/userOrder/changeTags',
  export: '/userOrder/export',
  exportRecord: '/exportRecord/pageList',
};
// 机构列表
export const Agency = {
  pageList: 'agency/pageList',
  pageListExport: 'agency/pageList/export',
  detail: 'agency/detail',
  save: 'agency/save',
  update: 'agency/update',
  list: 'agency/list',
  queryCity: 'agency/queryCity',
  auth: 'agency/auth',
  authPageList: 'agency/authPageList',
  infoFlowList: '/agency/infoFlowList',
};
// 机构审核
export const AgencyCheck = {
  list: '/agencyCheck/list',
  pageList: '/agencyCheckTemp/pageList',
  detail: '/agencyCheckTemp/detail',
  save: '/agencyCheckTemp/save',
  update: '/agencyCheckTemp/update',
  check: '/agencyCheckTemp/check',
  rollback: '/agencyCheckTemp/rollback',
  updateCreateUser: '/agencyCheckTemp/updateCreateUser',
  approvalStatus: '/agencyCheckTemp/approvalStatus',
};
// 排序值策略
export const SortValStrategy = {
  pageList: '/sortStrategy/pageList',
  detail: '/sortStrategy/detail',
  insert: '/sortStrategy/insert',
  update: '/sortStrategy/update',
  getProductList: '/sortStrategy/getProductList',
};

/* 统计相关 */
// 注册页数据统计
export const CountRegister = {
  pageCount: '/count/register/page/count',
  pageCountExport: '/count/register/page/export',
  pageDownloadCount: '/count/register/page/downloadCount',
  pageDownloadExport: '/count/register/page/downloadExport',
};
// 渠道数据统计
export const CountChannel = {
  count: '/count/channel/count',
  export: '/count/channel/export',
  countSpecial: '/count/channel/count/special',
  exportSpecial: '/count/channel/export/special',
  countChannelAgency: '/count/channel/agency/count',
  countChannelAgencyExport: '/count/channel/agency/export',
  timeframe: '/count/channel/timeframe/page',
  timeframeExport: '/count/channel/timeframe/export',
};
// 渠道api统计
export const CountApi = {
  count: '/count/api/count',
  export: '/count/api/export',
};
// 产品收益
export const CountProductIncome = {
  count: '/count/product/income/count',
  export: '/count/product/income/export',
  channelCount: '/count/product/channel/income/count',
  channelExport: '/count/product/channel/income/export',
  channelCountSpecial: '/count/product/channel/income/count/special',
  channelExportSpecial: '/count/product/channel/income/export/special',
};
// 产品星级回传
export const productStar = {
  pageList: '/count/productStr/count',
  export: '/count/productStr/export',
};
// 数据统计
export const CountTotal = {
  count: '/count/total/count',
  export: '/count/total/export',
};
// 首页
export const CountHome = {
  card: '/count/home/card',
};
// 城市获客统计
export const CountAcquire = {
  count: '/count/acquire/customers/city/count',
  export: '/count/acquire/customers/city/export',
};
// 助贷产品统计
export const CountProduct = {
  count: '/count/product/count',
  export: '/count/product/export',
};
// 助贷产品在线统计
export const CountProductOnline = {
  count: '/count/product-online/count',
  export: '/count/product-online/export',
  countHour: '/count/product-online/countHour',
};
// 助贷渠道统计
export const CountAgencyChannel = {
  count: '/count/agencyChannel/count',
  export: '/count/agencyChannel/export',
  countProduct: '/count/agencyChannel/product/count',
  exportProduct: '/count/agencyChannel/product/export',
};
// 助贷机构
export const CountAgency = {
  count: '/count/agency/count',
  export: '/count/agency/export',
};
// APP留资埋点
export const CountAppRemain = {
  count: '/count/app/remain/count',
  export: '/count/app/remain/export',
};
// APP留资埋点
export const CountAppChannelRemain = {
  count: '/count/app/channel/remain/count',
  export: '/count/app/channel/remain/export',
};
// 运营数据统计
export const CountOperate = {
  count: '/count/operate/count',
  export: '/count/operate/export',
};
// 运营管理
export const Operate = {
  pageList: '/operate/pageList',
  detail: '/operate/detail',
  insert: '/operate/insert',
  update: '/operate/update',
  delete: '/operate/del',
  operateTypeList: '/operate/operateTypeList',
  operateLocationList: '/operate/operateLocationList',
  operateUrlList: '/operate/operateUrlList',
};
export const PopCount = {
  count: '/count/pop/count',
  export: '/count/pop/export',
};
export const AppInfo = {
  pageList: '/appInfo/pageList',
  detail: '/appInfo/detail',
  insert: '/appInfo/insert',
  update: '/appInfo/update',
  selectAppInfo: '/appInfo/selectAppInfo',
};

export const ArticleType = {
  tabulation: 'articleType/tabulation',
  tables: 'articleType/tables',
  detail: 'articleType/detail',
  insert: 'articleType/insert',
  update: 'articleType/update',
};
export const Article = {
  tables: 'article/tables',
  detail: 'article/detail',
  insert: 'article/insert',
  update: 'article/update',
  del: 'article/del',
};
// 短链
export const ShortChain = {
  mainBody: '/shortChain/mainBody',
  pageList: '/shortChain/pageList',
  detail: '/shortChain/detail',
  insert: '/shortChain/insert',
  update: '/shortChain/update',
  del: '/shortChain/del',
};
// IP白名单
export const SysWhiteIp = {
  pageList: '/sysWhiteIp/pageList',
  detail: '/sysWhiteIp/detail',
  insert: '/sysWhiteIp/insert',
  update: '/sysWhiteIp/update',
};

export const ApiOrder = {
  tables: 'api/order/tables',
  categoryProduct: 'api/order/categoryProduct',
  orderDetail: 'api/order/orderDetail',
};

// 广告管理
export const OperateAdv = {
  page: '/operateAdv/page',
  list: '/operateAdv/list',
  selectById: '/operateAdv/selectById',
  insert: '/operateAdv/insert',
  update: '/operateAdv/update',
  delete: '/operateAdv/delete',
};
// 广告位管理
export const adSpaceConfig = {
  pageList: '/adSpaceConfig/pageList',
  detail: '/adSpaceConfig/detail',
  insert: '/adSpaceConfig/insert',
  update: '/adSpaceConfig/update',
};

// 广告商管理
export const OperateAdvType = {
  page: '/operateAdvType/page',
  list: '/operateAdvType/list',
  selectById: '/operateAdvType/selectById',
  insert: '/operateAdvType/insert',
  update: '/operateAdvType/update',
  delete: '/operateAdvType/delete',
};

// 信息流渠道
export const ChannelInfoFlow = {
  count: '/infoFlow/count', // get
  export: '/infoFlow/count/export', // get
  financeDetail: '/business/detail', // get 财务配置详情
  saveOrUpdateFinance: '/business/saveOrUpdateFinance', // post 新增或修改财务配置
  getChannelList: '/business/getChannelList', // get 设置权限列表
  saveOrUpdateBindingUser: '/business/saveOrUpdateBindingUser', // post 保存绑定用户
  userList: '/business/userlist', // get 查询用户下拉列表
  businessList: '/channelBusiness/businessList', // get 代理商下拉列表
};

// 代理商管理
export const ChannelBusiness = {
  sendSms: '/channelBusinessUser/login/sms.e',
  login: '/channelBusinessUser/login/login.e',
  pageList: '/channelBusiness/pageList',
  detail: '/channelBusiness/detail',
  userDetail: '/channelBusiness/userDetail',
  saveBusinessUser: '/channelBusiness/saveBusinessUser',
  saveBasic: '/channelBusiness/saveBasic',
  updateBusinessUser: '/channelBusiness/updateBusinessUser',
  updateBasic: '/channelBusiness/updateBasic',
};

// 短信配置-短信运营商
export const smsOperate = {
  pageList: '/smsOperateConfig/pageList',
  update: '/smsOperateConfig/update',
  detail: '/smsOperateConfig/detail',
  list: '/smsOperateConfig/list',
};

// 短信配置-发送短信配置
export const sedSmsConfig = {
  pageList: '/smsTemplateConfig/pageList',
  insert: '/smsTemplateConfig/insert',
  update: '/smsTemplateConfig/update',
  detail: '/smsTemplateConfig/detail',
};

export const RiskFail = {
  pageList: 'riskFailRecord/pageList',
};

export const NegativeScreenCount = {
  count: '/count/negative/screen/count',
  export: '/count/negative/screen/export',
};
export const ApplyFail = {
  pageList: '/user/order/applyFailList',
};
export const Platform = {
  pageList: '/platform/notice/pageList',
  exportList: '/platform/notice/exportList',
  detail: '/platform/notice/detail',
  insert: '/platform/notice/insert',
  update: '/platform/notice/update',
  delete: '/platform/notice/delete',
};
export const Feedback = {
  typeList: '/feedback/typeList',
  pageList: '/feedback/pageList',
};
export const StarCount = {
  pageList: '/count/starSating/count',
  export: '/count/starSating/export',
};

export const FlowProcess = {
  pageList: '/flowProcess/page',
  detail: '/flowProcess/selectById',
  insert: '/flowProcess/insert',
  delete: '/flowProcess/delete',
  nodeDetail: '/flowProcessNode/list',
  insertNode: '/flowProcessNode/insert',
};

// 短信配置-黑名单用户查询
export const SmsBlacklist = {
  pageList: '/smsBlackList/page',
  detail: '/smsBlackList/selectById',
  insert: '/smsBlackList/insert',
  delete: '/smsBlackList/delete',
  update: '/smsBlackList/update',
};

// app埋点-挡板配置
export const BaffleData = {
  pageList: '/baffleConfig/pageList',
  detail: '/baffleConfig/detail',
  insert: '/baffleConfig/insert',
  update: '/baffleConfig/update',
};

// app埋点-挡板埋点
export const BaffleDataPoint = {
  count: '/count/app/baffle/count',
};

// 投放管理-协议配置
export const ProtocolConfig = {
  pageList: '/protocolConfig/page',
  detail: '/protocolConfig/selectById',
  update: '/protocolConfig/update',
  insert: '/protocolConfig/insert',
  delete: '/protocolConfig/delete',
  list: '/protocolConfig/list',
};

// 用户管理-api渠道用户撞库
export const ApiChannelUser = {
  pageList: '/channel/filter/input/page',
  pageListExport: '/channel/filter/input/export',
};

// 用户管理-虚拟号段
export const VirtualNumber = {
  pageList: '/virtualNumber/page',
  update: '/virtualNumber/save',
  delete: '/virtualNumber/del',
};

// 投放管理-非法姓名配置
export const IllegalNameConfig = {
  pageList: '/illegalNameConfig/page',
  detail: '/illegalNameConfig/selectById',
  insert: '/illegalNameConfig/insert',
  update: '/illegalNameConfig/update',
  delete: '/illegalNameConfig/delete',
};
// 投放管理-web端数据统计
export const countOverloan = {
  pageList: '/count/overloan/pageList',
  export: '/count/overloan/export',
};

// 电子签配置-电子签供应商
export const SupplierConfig = {
  pageList: '/signProvider/pageList',
  detail: '/signProvider/detail',
  update: '/signProvider/update',
};

// 百家姓
export const UserSurname = {
  page: '/userSurname/page',
  del: '/userSurname/del',
  insert: '/userSurname/insert',
};

// 短信去重
export const SmsDuplicateRelevance = {
  selectSmsType: '/smsDuplicateRelevance/selectSmsType',
  page: '/smsDuplicateRelevance/page',
  detail: '/smsDuplicateRelevance/detail',
  insert: '/smsDuplicateRelevance/insert',
  update: '/smsDuplicateRelevance/update',
  del: '/smsDuplicateRelevance/del',
};

// 运营商二要素
export const OperatorTwoElement = {
  pageList: '/phoneAuthorizeConfig/pageList',
  detail: '/phoneAuthorizeConfig/detail',
  update: '/phoneAuthorizeConfig/update',
};

// 姓名身份证二要素
export const AuthorizeConfig = {
  pageList: '/authorizeConfig/pageList',
  detail: '/authorizeConfig/detail',
  update: '/authorizeConfig/update',
};

// 运营商二要素
export const ApplyFailList = {
  pageList: '/channel/order/applyFailList',
};

// 手机在网状态
export const phoneOnlineCheckConfig = {
  pageList: '/phoneOnlineCheckConfig/pageList',
  detail: '/phoneOnlineCheckConfig/detail',
  update: '/phoneOnlineCheckConfig/update',
};

// 撞库用户
export const CollisionUserList = {
  pageList: '/filterTest/userTable',
  detail: '/filterTest/userDetail',
  updateUser: '/filterTest/filter',
  filterRecord: '/filterTest/filterRecord',
  filterRecordRemark: '/filterTest/filterRecordRemark',
  insertRecord: '/filterTest/filterRecordRemark/insert',
  updateRecord: '/filterTest/filterRecordRemark/update',
  filterProduct: '/filterTest/filterProduct',
};
