import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordNormalized,
} from 'vue-router';
import Login from '@/views/login/login.vue';
import notFund from '@/views/error-page/404.vue';
import basics from '@/libs/basics.js';
import { mainStore } from '@/store';
import { routerMenu } from './getMenu.js';
import { tsTypes } from '@/libs/interfaceType';
import _, { isEmpty } from 'lodash-es';
import page from '@/router/page';
import localRouter from '@/router/localRouter';
import http from '@/libs/service/http';
// import { ossPath } from '@/libs/api';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/404',
    name: '404',
    component: notFund,
    meta: {
      title: '404',
    },
  },
  {
    path: '/:pathMatch(.*)*', // 解决[Vue Router warn]: No match found for location with path "/xxx"警告
    component: notFund,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登录',
    },
  },
  {
    path: '/channelBackground',
    name: 'channelBackground',
    component: () => import('@/views/channelManagement/channelList/channelLinkTable.vue'),
    meta: {
      title: '渠道商链接',
    },
  },
  {
    path: '/agentOperatingTable',
    name: 'agentOperatingTable',
    component: () => import('@/views/channelManagement/channelList/agentOperatingTable.vue'),
    meta: {
      title: '渠道商链接',
    },
  },
  // ...localRouter,
];

// 信息流渠道
const channelProviderRoutes = [
  {
    path: '/channelInfo',
    name: 'channelInfo',
    component: () => import('@/components/main/main.vue'),
    meta: {
      title: '渠道管理',
    },
    title: '渠道管理',
    children: [
      {
        path: '/channelInfoFlow',
        name: 'channelInfoFlow',
        component: () => import('@/views/channelInfoFlow/channelInfoFlow.vue'),
        meta: {
          title: '信息流渠道',
        },
        title: '信息流渠道',
      },
    ],
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
});

// 不需要权限的菜单
export const promise: string[] = ['channelBackground', 'agentOperatingTable', 'channelInfo'];

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (
      window.location.href.indexOf('test') > -1 ||
      window.location.href.indexOf('localhost') > -1 ||
      window.location.href.indexOf('192') > -1
    ) {
      document.title = '测试环境-后台管理系统';
    }
    // 获取后台的动态接口域名
    const store = mainStore();
    // if (isEmpty(store.urlMap)) {
    //   const res: any = await http.get(ossPath + '/0');
    //   store.urlMap = res;
    //   const host = window.location.origin;
    //   let companyUrl = res.default;
    //   if (res[host]) companyUrl = res[host];
    //   store.bodyCode = companyUrl;
    //
    //   if (!store.axiosBaseURL) {
    //     const resp = await http.get(ossPath + '/50');
    //     if (resp?.url) store.axiosBaseURL = resp.url + '/admin';
    //   }
    // }

    if (promise.indexOf(to.name as string) > -1) {
      next();
    } else if (!basics.Storage.isLogin()) {
      if (to.path !== '/login') {
        // 未登录且要跳转的页面不是登录页
        next({ path: '/login' });
      } else {
        next();
      }
    } else if (basics.Storage.isLogin() && to.path === '/login') {
      // 已登录且要跳转的页面是登录页
      next({ path: '/' });
    } else {
      saveNavTag(to);
      if (!store.init) {
        let routerList: any[] = [];
        if (basics.Storage.cacheReadSession().businessId) {
          // 渠道商账号
          channelProviderRoutes.forEach((value: any) => {
            console.log(value, '信息流渠道');
            router.addRoute(value);
            routerList.push(value);
          });
          store.init = true;
        } else {
          // 管理员账号
          const obj: any = await routerMenu();
          routerList = _.cloneDeep(obj).routerList;

          // 往router里面添加新的路由规则
          routerList.forEach((value: RouteRecordNormalized) => {
            router.addRoute(value);
          });
        }

        store.menuList = routerList;

        const path = to.path === '/' ? routerList[0]?.children[0].path : to.path;
        next({ path: path, replace: true });
      } else {
        next();
      }
    }
  }
);

interface keyValueParams {
  name: string;
  title: string;
}

function saveNavTag(route: RouteLocationNormalized) {
  if (route.name) {
    const obj = { name: route.name, title: route.meta.title };
    const arr = basics.Storage.getLocal('tagNavList') || [];
    const flag = arr.find((item: keyValueParams) => {
      return item.name === obj.name;
    });
    if (!flag) {
      arr.push(obj);
    }
    basics.Storage.setLocal('tagNavList', arr);
  }
}

router.afterEach((route) => {
  window.scrollTo(0, 0);
});

export default router;
