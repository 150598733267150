/*
 * TODO 此处添加logo图标、项目标题的图片
 * */
import logo from '@/assets/images/logo.png';
import logoTitle from '@/assets/images/logo.png';

const runType = import.meta.env.APP_FLAG;

let apiPrefixPath = '';

// 项目名称
const PROJECT_NAME = '荟信钱包';
// 公司名称
const COMPANY_NAME = '四川荟信数科网络科技有限公司';

let ossPath = '';
/*
 * TODO 需要修改 SIGN_KEY 签名
 * */
const SIGN_KEY = '8ku4jazkh2nkab7ccuyksuxssa3348uzsfdeupr3mr4yby2p2ptetr2wvfkxnaxc';

/*
/*
 * TODO 需要添加或修改以下内容
 *  apiPrefixPath 后台请求地址
 *  ossPath OSS图片地址
 * */
switch (runType) {
  case 'pro': // 线上环境
    apiPrefixPath = 'https://gw.xiaoyiqb.cn/admin/';
    ossPath = 'https://oss.xiaoyiqb.cn/';
    break;
  case 'test': // 外网测试环境
    apiPrefixPath = 'https://test-gw.xiaoyiqb.cn/admin/';
    ossPath = 'https://test-oss.xiaoyiqb.cn/';
    break;
  default: // 默认环境
    apiPrefixPath = 'https://test-gw.xiaoyiqb.cn/admin/';
    ossPath = 'https://test-oss.xiaoyiqb.cn/';
    break;
}
export default {
  apiPrefixPath,
  SIGN_KEY,
  ossPath,
  logo,
  logoTitle,
  PROJECT_NAME,
  COMPANY_NAME,
};
